import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './containers/HomePage';


const theme = createTheme({
  palette: {
    primary: {
      main: '#6ebaf2',
    },
    secondary: {
      main: '#6EF2E8',
    },
    background: {
      paper: '#fafaff',
      default: '#fff',
    },
  },
});

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route exact path="/" component={HomePage} />
        </Routes>
      </Router>
      <HomePage />
    </ThemeProvider>
  );
}