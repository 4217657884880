import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyAkbH470umFS05QPYRppVewBhv8gNHvqRk",
    authDomain: "qualiaxr.firebaseapp.com",
    projectId: "qualiaxr",
    storageBucket: "qualiaxr.appspot.com",
    messagingSenderId: "1032689261538",
    appId: "1:1032689261538:web:ddde5bf7d72538fa9d37bb",
    measurementId: "G-XM0QVV211H"
  };

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
