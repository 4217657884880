import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { ref, getDownloadURL } from "firebase/storage";
import { logEvent } from "firebase/analytics";
import coverImg from '../assets/xr_bg0.avif';
import brandImg from '../assets/qualiaXR.png';
import { storage, analytics } from '../server/config';

const Cover = styled(Box)(() => ({
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    objectFit: 'cover'
    }));

const Brand = styled(Box)(({theme}) => ({
    width: '50vw',
    bottom: '16vh',
    right: '3vw',
    [theme.breakpoints.down('md')]: {
        width: '70vw',
        bottom: '10vh'
    },
    [theme.breakpoints.down('sm')]: {
        width: '90vw',
        bottom: '10vh',
        right: '5vw',
      },
    position: 'absolute',
    }));

function HomePage() {

  useEffect(() => {
    logEvent(analytics, 'page_loaded');
  },[])

  const addDefaultSrc = (ev) => {
    getDownloadURL(ref(storage, '/public/xr_bg0.png')).then( url => {
      ev.target.src = url;
      logEvent(analytics, 'cover_image_error');
    });
  }
  
    return (
      <Box>
        <Cover component="img" src={coverImg} alt="cover" onError={(ev) => addDefaultSrc(ev)} />
        <Brand component="img" src={brandImg} alt="brand" />
      </Box>
    );
  }
  
  export default HomePage;